<template>
    <CompTable ref="comp_table" title="按行政纬度统计栏目更新提醒数" :summary="initialLevel === 6" :dataBefore="onDataBefore" :columns="getColumns()" :searchData="searchData" :table-api="tableApi" @on-reset="onReset">
        <template #operate>
            <Button type="info" @click="$refs.comp_table.export()">导出</Button>
        </template>
        <template #search="evt">
            <RadioGroup v-if="initialLevel !== 6" v-model="evt.search.onlineType" type="button">
                <Radio label="0">全部示范社区</Radio>
                <Radio label="1">市级示范社区</Radio>
                <Radio label="2">区级示范社区</Radio>
                <Radio label="3">街镇级示范社区</Radio>
            </RadioGroup>
            <RadioGroup v-if="initialLevel !== 6" v-model="evt.search.level" type="button">
                <Radio :label="3">按区</Radio>
                <Radio :label="4">按街镇</Radio>
                <Radio :label="5">按社区</Radio>
            </RadioGroup>
        </template>
    </CompTable>
</template>

<script>
import CompTable from "@/views/jointly/components/comp-table.vue"
import Route from "../communitymobilization/entity/Route"

export default {
    components: {
        CompTable,
    },

    data() {
        let columnCode = this.$core.getUrlParam("c") || ""
        let initialLevel = columnCode ? 6 : 3

        return {
            tableApi: `/gateway/api/symanage/pc/columnTree/listBlackColumnByOrgCode?level=${columnCode ? 5 : 3}&columnCode=${columnCode}`,
            initialLevel: initialLevel,
            level: initialLevel,
            searchData: {
                columnCode,
                level: columnCode ? 5 : 3,
            },
        }
    },

    methods: {
        onDataBefore(data) {
            if (data.onlineType) {
                data.onlineStatus = 1
                data.onlineType = data.onlineType === "0" ? "" : data.onlineType
            }
            if (this.level !== 4) {
                delete data.zoneCode
            }
            if (this.level !== 5) {
                delete data.streetCode
            }
            if (this.level !== 6) {
                delete data.orgCode
            }
            if (!data.orgCode) {
                this.level = data.level
            }
        },

        onReset() {
            this.level = this.initialLevel
        },

        getColumns() {
            return [
                {
                    title: "区",
                    minWidth: 120,
                    align: "center",
                    key: "zoneName",
                    render: (h, p) => {
                        return h(
                            "p",
                            this.initialLevel !== 6
                                ? {
                                      style: {
                                          color: "#2faaf7",
                                          cursor: "pointer",
                                      },
                                      on: {
                                          click: () => {
                                              this.level = 4
                                              this.$refs.comp_table.search({
                                                  zoneCode: p.row.zoneCode,
                                                  level: this.level,
                                              })
                                          },
                                      },
                                  }
                                : null,
                            p.row.zoneName
                        )
                    },
                },
                ...(this.level >= 4
                    ? [
                          {
                              title: "街道",
                              minWidth: 120,
                              align: "center",
                              key: "streetName",
                              render: (h, p) => {
                                  return h(
                                      "p",
                                      this.initialLevel !== 6
                                          ? {
                                                style: {
                                                    color: "#2faaf7",
                                                    cursor: "pointer",
                                                },
                                                on: {
                                                    click: () => {
                                                        this.level = 5
                                                        this.$refs.comp_table.search({
                                                            streetCode: p.row.streetCode,
                                                            level: this.level,
                                                        })
                                                    },
                                                },
                                            }
                                          : null,
                                      p.row.streetName
                                  )
                              },
                          },
                      ]
                    : []),
                ...(this.level >= 5
                    ? [
                          {
                              title: "社区",
                              minWidth: 150,
                              align: "center",
                              key: "orgName",
                              render: (h, p) => {
                                  return h(
                                      "p",
                                      this.initialLevel !== 6
                                          ? {
                                                style: {
                                                    color: "#2faaf7",
                                                    cursor: "pointer",
                                                },
                                                on: {
                                                    click: () => {
                                                        this.level = 6
                                                        this.$refs.comp_table.search({
                                                            orgCode: p.row.orgCode,
                                                            level: 5,
                                                        })
                                                    },
                                                },
                                            }
                                          : null,
                                      p.row.orgName
                                  )
                              },
                          },
                      ]
                    : []),
                ...(this.level === 6
                    ? [
                          {
                              title: "栏目名称及编码",
                              minWidth: 250,
                              align: "center",
                              key: ["columnName", "columnCode"],
                              render: (h, p) => {
                                  return h("p", p.row.columnName + " - " + p.row.columnCode)
                              },
                          },
                      ]
                    : []),
                {
                    title: "昨日提醒次数",
                    minWidth: 130,
                    align: "center",
                    key: "yesterday",
                },
                {
                    title: "本周提醒次数",
                    minWidth: 130,
                    align: "center",
                    key: "week",
                },
                {
                    title: "本月提醒次数",
                    minWidth: 130,
                    align: "center",
                    key: "month",
                },
                {
                    title: "总提醒次数",
                    minWidth: 130,
                    align: "center",
                    key: "total",
                },
                ...(this.level === 6
                    ? [
                          {
                              title: "最新提醒时间",
                              minWidth: 180,
                              align: "center",
                              key: "null",
                              content: p => (p.gmtCreate ? this.$core.formatDate(new Date(p.gmtCreate), "yyyy/MM/dd hh:mm:ss") : "-"),
                              render: (h, params) => {
                                  return h("p", params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy/MM/dd hh:mm:ss") : "-")
                              },
                          },
                          {
                              title: "路径",
                              minWidth: 300,
                              align: "center",
                              key: "null",
                              render: (h, params) => {
                                  return h("p", params.row.columnUrl || "-")
                              },
                          },
                          {
                              title: "操作",
                              align: "center",
                              fixed: "right",
                              minWidth: 150,
                              render: (h, p) => {
                                  return [
                                      h(
                                          "Button",
                                          {
                                              props: {
                                                  type: "info",
                                                  size: "small",
                                              },
                                              style: "margin:0 3px",
                                              nativeOn: {
                                                  click: () => {
                                                      Route.jump("/columnreminderpeople", {
                                                          t: p.row.columnName + " - " + p.row.columnCode,
                                                          o: p.row.orgCode,
                                                          c: p.row.columnCode,
                                                          type: "column",
                                                      })
                                                  },
                                              },
                                          },
                                          "提醒记录"
                                      ),
                                  ]
                              },
                          },
                      ]
                    : []),
            ]
        },
    },
}
</script>

<style></style>
